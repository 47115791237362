import { ApplePaySupportedNetworksItem } from "@src/runtime-configuration/DomainConfiguration";

export const isPageVisible = (function () {
  let stateKey: string, eventKey: any;
  const keys: any = {
    hidden: "visibilitychange",
    webkitHidden: "webkitvisibilitychange",
    mozHidden: "mozvisibilitychange",
    msHidden: "msvisibilitychange",
  };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function (handler?: (this: Document, ev: any) => any) {
    if (handler) {
      document.addEventListener(eventKey, handler);
      return () => {
        document.removeEventListener(eventKey, handler);
      };
    }
    return !(document as any)[stateKey];
  };
})();

export function pageBecomeVisible() {
  return new Promise<void>((resolve) => {
    const destroyListener = isPageVisible(() => {
      if (isPageVisible()) {
        destroyListener();
        resolve();
      }
    }) as () => void;
  });
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isWebView() {
  return (
    (window.navigator as any).standalone === true ||
    window.matchMedia("(display-mode: standalone)").matches
  );
}

/**
 * @param {Object} object
 * @param {string} key
 * @return {any} found
 */
export function getParameterCaseInsensitive(object: object, key: string) {
  if (object == null) {
    return null;
  }
  const asLowercase = key.toLowerCase();
  const foundKey = Object.keys(object).find(
    (k) => k.toLowerCase() === asLowercase
  );
  if (!foundKey) {
    return null;
  }

  return foundKey;
}

export const clipboard = (function (window, document, navigator) {
  let textArea: any;
  let copy: any;

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  function createTextArea(text: any) {
    textArea = document.createElement("textArea");
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range;
    let selection;

    if (isSafari()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  // eslint-disable-next-line prefer-const
  copy = function (text: any) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  };

  return {
    copy,
  };
})(window, document, navigator);

export function getBrowserInfo(): string {
  return btoa(
    JSON.stringify({
      AcceptHeader: "*/*",
      JavaEnabled: navigator.javaEnabled(),
      JavaScriptEnabled: true,
      Language: navigator.language,
      ColorDepth: screen.colorDepth.toString(),
      Height: screen.height.toString(),
      Width: screen.width.toString(),
      TimeZone: new Date().getTimezoneOffset().toString(),
      UserAgent: navigator.userAgent,
    })
  );
}

export const getSupportedNetworksFromConfig = (
  networks: ApplePaySupportedNetworksItem[]
): string[] => {
  let supportedNetworks: string[] = [];
  networks.forEach((network) => {
    if (network.name === "default") {
      supportedNetworks = supportedNetworks.concat(network.value ?? []);
    }

    if (
      typeof network.name === "number" &&
      ApplePaySession.supportsVersion(network.name)
    ) {
      supportedNetworks = supportedNetworks.concat(network.value ?? []);
    }
  });

  return supportedNetworks;
};
