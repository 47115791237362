import { Logger } from "@cloudpayments/vue-utils";
import { getSupportedNetworksFromConfig } from "@src/common/Utils";
import { IApplePayPayment } from "@src/runtime-configuration/DomainConfiguration";

export class ApplePayApi {
  public static isApplePayAvailable(): Promise<void> {
    if ("ApplePaySession" in window) {
      try {
        if (ApplePaySession.canMakePayments()) {
          return Promise.resolve();
        } else {
          return Promise.reject("ApplePaySession CanMakePayments false");
        }
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject("ApplePaySession not in Window");
  }

  private static createApplePaymentRequest(
    terminalUrl: string,
    amount: number,
    currency: string,
    requireEmail: boolean,
    applePayOptions: IApplePayPayment,
    label?: string
  ): ApplePayJS.ApplePayPaymentRequest {
    Logger.LogInfo("createApplePaymentRequest", {
      terminalUrl: terminalUrl,
      amount: amount,
      currency: currency,
      requireEmail: requireEmail,
      label: label,
    });

    const supportedNetworks = getSupportedNetworksFromConfig(
      applePayOptions.supportedNetworks
    );

    const apr = {
      countryCode: applePayOptions.countryCode,
      currencyCode: currency,
      supportedNetworks,
      merchantCapabilities: applePayOptions.merchantCapabilities,
      total: {
        label: label ?? "Pay to " + terminalUrl,
        amount: amount.toString(),
      },
    } as ApplePayJS.ApplePayPaymentRequest;

    if (requireEmail) {
      apr.requiredShippingContactFields = ["email"];
    }

    return apr;
  }

  public static getApplePaySession(
    terminalUrl: string,
    amount: number,
    currency: string,
    requireEmail: boolean,
    applePayOptions: IApplePayPayment,
    label?: string
  ): ApplePaySession {
    const paymentRequest = ApplePayApi.createApplePaymentRequest(
      terminalUrl,
      amount,
      currency,
      requireEmail,
      applePayOptions,
      label
    );

    const applePaySession = new ApplePaySession(1, paymentRequest);

    return applePaySession;
  }
}
